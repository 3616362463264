import React from 'react'
import { Script } from 'gatsby'
import * as css from './newsletter-signup.module.css'

const NewsletterSignupPg = () => <>
	<div className={`${css.form} klaviyo-form-XBiU9B`}></div>
	<Script src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=TvAzQX" />
</>

export default NewsletterSignupPg
